<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <h4 class="tw-mb-0">{{ $t('user.assignRole') }}</h4>
                        <small class="tw-text-gray-400">{{
                            $t('user.defineRoleForUser')
                        }}</small>
                    </div>
                    <div class="tw-justify-end">
                        <div class="tw-text-red-600" v-if="errors.has('roles')">
                            {{ errors.first('roles') }}
                        </div>
                        <input
                            v-model="search"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('search')"
                        />
                    </div>
                </div>
                <ul
                    class="tw-mt-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-36 tw-overflow-auto"
                >
                    <li v-for="role in userRole" :key="role.id">
                        <ts-checkbox
                            class="tw-m-1"
                            v-model="model.roles"
                            :value="role.id"
                            >{{ role.name }}</ts-checkbox
                        >
                    </li>
                </ul>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'

export default {
    name: 'roleList',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {},
            search: null
        }
    },
    computed: {
        userRole () {
            if (this.search) {
                return this.$store.state.auth.user.userRole.filter(
                    r =>
                        r.name
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) > -1
                )
            }
            return this.$store.state.auth.user.userRole
        }
    },
    created () {
        this.model = this.value
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
<style scoped>
ol,
ul {
    padding-left: 2px;
}
</style>
